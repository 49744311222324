import "core-js/modules/web.dom.iterable";
export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      var arr = ['addFloor', 'addProject', 'sendRecords', 'addLessee', 'payNoticeDetail',
      // 'serviceDetail',
      'AddLessee'];
      return arr;
    },
    visitedViews: function visitedViews() {
      var _this = this;
      var arr = [];
      this.$store.state.tagsView.visitedViews.forEach(function (e) {
        arr.push(_this.getTitle(e.title));
      });
      console.log('🎄line:39', arr);
      return arr;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  methods: {
    getTitle: function getTitle(data) {
      console.log('🎄line:50', data);
      var name = '';
      switch (data) {
        // 报表
        case '报修报表':
          name = 'reportList';
          break;
        // 报表
        case '会议码明细':
          name = 'mtRecordList';
          break;

        // 工作流
        case '工作流':
          name = 'msgList';
          break;

        // 系统管理
        case '菜单管理':
          name = 'menusList';
          break;
        case '通用字典':
          name = 'dictList';
          break;
        case '角色权限管理':
          name = 'rolesList';
          break;
        case '用户组管理':
          name = 'sysUserGroupList';
          break;
        case '系统用户管理':
          name = 'accountList';
          break;
        case '日志管理':
          name = 'Log';
          break;
        case '审批流管理':
          name = 'approvalProcessList';
          break;

        // 项目信息管理
        case '项目管理':
          name = 'projectList';
          break;
        case '楼宇管理':
          name = 'projectBuildList';
          break;
        case '车位管理':
          name = 'projectCarportList';
          break;
        case '楼层管理':
          name = 'floorList';
          break;

        // 信息公告
        case '活动管理':
          name = 'activityList';
          break;
        case '公告管理':
          name = 'sysNewsList';
          break;
        case '集团资源管理':
          name = 'groupResourceList';
          break;
        case '消息模板通知管理':
          name = 'msgTemplateList';
          break;
        case '消息发送管理':
          name = 'msgSendList';
          break;

        // 租赁服务
        case '租户管理':
          name = 'lesseeList';
          break;
        case '员工管理':
          name = 'employeesList';
          break;
        case '常用服务申请管理':
          name = 'overtimeList';
          break;
        case '常用服务设置':
          name = 'applySettingList';
          break;
        case '月租车管理':
          name = 'parkApplyList';
          break;
        case '微信商户付款明细':
          name = 'wxpaymentList';
          break;
        case '付款通知书管理':
          name = 'billList';
          break;
        case '账单管理':
          name = 'billItemList';
          break;
        case '物业报修':
          name = 'repairList';
          break;

        // 投诉建议
        case '投诉管理':
          name = 'adviseFalseList';
          break;
        case '建议墙管理':
          name = 'adviseTrueList';
          break;
        // 设备管理
        case '通行管理':
          name = 'ThroughMGT';
          break;
        case '设备资产管理':
          name = 'deviceMGT';
          break;
        // 访客邀约
        case '访客邀约管理':
          name = 'VisitList';
          break;
        case '访客黑名单管理':
          name = 'blacklist';
          break;
        case 'vip访客管理':
          name = 'vipVisitList';
          break;
      }
      return name;
    }
  }
};